import PACKAGE from '../package.json'
import { startCase, lowerCase } from './utils/change-case'
import { PATH_PAGE } from 'src/routes/paths'

export const PATH_AFTER_LOGIN = PATH_PAGE.root // as '/app/base'

export const APPLICATION_NAME = startCase(lowerCase(PACKAGE.name))
export const APPLICATION_VERSION = PACKAGE.version
export const APPLICATION_DESCRIPTION = PACKAGE.description
export const APPLICATION_COPYRIGHT = '© 2024 Visiting Angels'
export const APPLICATION_AUTHOR = PACKAGE.author
export const APPLICATION_KEYWORDS = PACKAGE.keywords
export const APPLICATION_THEME = 'default' // default, dark
export const APPLICATION_LANGUAGE = 'en' // en, de, fr, pt, zh, es, ru, ja, nl
export const APPLICATION_LAYOUT = 'default' // default, compact, mini
export const APPLICATION_SIDEBAR = 'full' // full, compact, mini
export const APPLICATION_SIDEBAR_VARIANT = 'default' // default, solid, transparent
export const APPLICATION_SIDEBAR_POSITION = 'left' // left, right
export const APPLICATION_SIDEBAR_FOOTER = true // true, false
export const APPLICATION_SIDEBAR_FOOTER_TEXT = 'Visiting Angels' // true, false
export const APPLICATION_HEADER = 'fixed' // fixed, static
export const APPLICATION_HEADER_POSITION = 'relative' // relative, absolute
export const APPLICATION_HEADER_RESPONSIVE = true // true, false
export const APPLICATION_HEADER_SEARCH_HOVER = false // true, false
export const APPLICATION_HEADER_BREADCRUMBS = true // true, false
export const APPLICATION_HEADER_DARK_MODE = false // true, false
export const APPLICATION_HEADER_RESPONSIVE_BREAKPOINT = 'md' // xs, sm, md, lg, xl, xxl

export const APPLICATION_QUERY_PARAM_SESSION_NAME = `${PACKAGE.name}-params-session`
export const APPLICATION_REDUX_LOCAL_STORAGE_NAME = `${PACKAGE.name}-redux-`

export const APPLICATION_URL = 'https://www.visitingangelsapp.com/'

export const LEAD_TYPE = {
  CLIENT: 'CLIENT',
  EMPLOYEE: 'EMPLOYEE',
}

export const APP_FLOW_TYPE = {
  CARE: 'CARE',
  CARE_GIVER: 'CARE_GIVER',
  PROVIDER: 'PROVIDER',
}

export const WEEK_DAYS = [
  'SUNDAY',
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
]
